import { redirect, useLoaderData, useNavigate } from 'react-router-dom';
import { deleteProblem, getImage, getProblem } from '../util/server_util';
import InlineLatex from '../components/InlineLatex';

const ProblemPage = () => {
    const { problem, problemImages, solutionImages } = useLoaderData();
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate(`/edit-problem/${problem._id}`, { state: { problem } });
    };

    const handleDelete = async () => {
        try {
            await deleteProblem(problem._id);
            navigate('/problems');
        } catch (error) {
            console.error('Error deleting problem:', error);
        }
    };

    if (problem === null) {
        return (
            <div>
                <h1> PROBLEM DNE </h1>
                <button
                    onClick={() => navigate('/problems')}
                    className="bg-gray-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Back to Problems
                </button>
            </div>
        );
    }
    return (
        <div className="container mx-auto p-8">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-3xl font-bold">{problem.title}</h1>
                <button
                    onClick={() => navigate('/problems')}
                    className="bg-gray-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Back to Problems
                </button>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">Problem</h2>
                <div className="bg-gray-100 p-4 rounded-lg mb-4">
                    <InlineLatex text={problem.problemText} />
                </div>
                <div className="overflow-x-auto flex space-x-4">
                    {problemImages.map((imageSrc, index) => (
                        <img
                            key={index}
                            src={imageSrc}
                            alt={""}
                            className="max-w-xs max-h-64 object-contain rounded-lg border"
                        />
                    ))}
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">Solution</h2>
                <div className="bg-gray-100 p-4 rounded-lg mb-4">
                    <InlineLatex text={problem.solutionText} />
                </div>
                <div className="overflow-x-auto flex space-x-4">
                    {solutionImages.map((imageSrc, index) => (
                        <img
                            key={index}
                            src={imageSrc}
                            alt={""}
                            className="max-w-xs max-h-64 object-contain rounded-lg border"
                        />
                    ))}
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">Answer</h2>
                <p className="bg-gray-100 p-4 rounded-lg mb-4">
                    <InlineLatex text={problem.answer} />
                    <span className="ml-2">({problem.units})</span>
                </p>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">Difficulty</h2>
                <p className="bg-gray-100 p-4 rounded-lg mb-4">{problem.difficulty} / 5</p>
            </div>

            <div className="flex justify-end space-x-4 mt-8">
                <button
                    onClick={handleEdit}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Edit Problem
                </button>
                <button
                    onClick={handleDelete}
                    className="bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Delete Problem
                </button>
            </div>
        </div>
    );
};

export default ProblemPage;

export const problem_loader = async ({ params, request }) => {
    const fetchImages = async (imageIds) => {
        try {
            const imagePromises = imageIds.map(id =>
                getImage(id)
            );
            const images = await Promise.all(imagePromises);
            return images.map(image => URL.createObjectURL(image.data));
        } catch (error) {
            console.error('Error fetching images:', error);
            return [];
        }
    }
    try {
        const problem = await getProblem(params.prob_id);
        if (problem.data._id === undefined) {
            return null;
        }
        const problemImages = await fetchImages(problem.data.problemImages);
        const solutionImages = await fetchImages(problem.data.solutionImages);
        return { problem: problem.data, problemImages: problemImages, solutionImages: solutionImages };
    }
    catch (e) {
        return redirect("/login");
    }
}
