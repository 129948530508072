import { redirect, useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import { oauth2callback } from "../util/server_util";
import { useContext, useEffect } from "react";
import { LoginContext } from "../App";

const Oauth2Callback = () => {
    const response = useLoaderData();
    const navigate = useNavigate();
    const { setLoggedIn } = useContext(LoginContext);

    useEffect(() => {
        setLoggedIn(true);
        // const res = { state: { status: response.status, user: response.user } };
        console.log("res: ", response);
        if (response.user === undefined || response.user.scope === 'a')
            navigate(`/user-portal/${response.status}`);
        else if (response.user.scope === 'b')
            navigate("/admin/calendar");
    });

    return <div>LOGGED IN YAY</div>;
}

export default Oauth2Callback;

export const oauth_loader = async ({ request }) => {
    const url = new URL(request.url);
    if (url.searchParams.has("error"))
        return { status: "oauth2" };
    try {
        let user = await oauth2callback(url.searchParams);
        console.log("user:", user);

        return { status: "ok", user: user.data };
    }
    catch (e) {
        console.log("dne", e);
        return { status: "dne" };
    }
}
