import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import React, { useEffect, useRef, useState } from 'react';

const MyCalendar = ({ showEvents, hideEvents }) => {
    const calendarRef = useRef(null);
    const cal = () => calendarRef.current.getApi();
    const [curSelection, setCurSelection] = useState(null);
    console.log("show events:", showEvents);

    if (calendarRef.current !== null) {
        for (const event of cal().getEvents()) {
            console.log("event:", event);
            event.remove();
        }
        for (const e of showEvents) {
            cal().addEvent(e);
        }
    }

    const selectionCallback = (selectionInfo) => {
        console.log("selection made: ", selectionInfo);
        // const api = cal();
        // console.log("events:", api.getEvents());
        // api.addEvent(selectionInfo);        
    }
    const unSelectionCallback = (selectionInfo) => {
        console.log("unselection made: ", selectionInfo);
        // cal().remove(selectionInfo);
        setCurSelection(null);
    }

    return (
        <FullCalendar ref={calendarRef}
            plugins={[timeGridPlugin, interactionPlugin]}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: ''
            }}
            initialView='timeGridWeek'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            initialEvents={showEvents}
            unselectAuto={false}
            nowIndicator={true}
            select={selectionCallback}
            unselect={unSelectionCallback}
        />
    );
};

export default MyCalendar;
