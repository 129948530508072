import atomImg from '../static/atomic.svg';
import teacherImage from '../static/teacher.png';
import neiloImage from '../static/neilo.png';
import subjects_baba from '../static/subjects_baba.json'
import subjects_neilo from '../static/subjects_neilo.json'

import Card from '../components/Card.js'
import { Link, useNavigate } from 'react-router-dom';
import { PROD } from '../App';
import Testimonials from '../components/Testimonials'

const BabaDescription = () => {
    return (
        <div className="text-lg my-4">
            Educational background:
            <br />
            Bachelors (EE): Indian Institute of Technology, India
            <br />
            Masters (CS): Illinois Institute of Technology, Chicago
            <br />

            <br />
            Teaching experience (Math & Physics): 5 years (1 year fulltime)
            <br />

            <br />
            <p className="font-bold">Teaching style:</p>
            <ul className="list-inside list-disc">
                <li>
                    {"Firm believer in teaching concepts, encouraging questions no matter how wild they are"}
                </li>
                <li>
                    {"Students are expected to spend time at home reviewing learnt concepts and trying new things on their own"}
                </li>
                <li>
                    {"For offline questions, students can text or email for help any time"}
                </li>
                <li>
                    {"Student grades arent guaranteed as they depend on whether student is able to come up to speed. However, most of my students do end up with A grade, but I take pride in the fact that they developed an interest in the subject at the end of the course"}
                </li>
                <li>
                    {"Choice of group or private lessons are available, online as well as in person (Cupertino, CA)"}
                </li>
            </ul>

            <br />
            <p className="font-bold">Subjects taught:</p>
            <ul className="list-inside list-disc">
                <li>Precalc (honors, AP)</li>
                <li>Calc (AP Calc AB, AP Calc BC)</li>
                <li>Warm up to Physics: For those not taking physics currently but slowly get introduced to concepts over a year</li>
                <li>Physics (regular, honors, AP Physics 1, AP Physics 1&2 combo, AP Physics C)</li>
            </ul>

            <hr></hr>
            <br />
            <p className="font-bold">First Class Free!</p>

            Email: nilanjan@apguidance.com
            <br />
            Phone: (669) 302-6791

            <br />
            <br />
            <p className="text-2xl text-blue-500"><Link to="/contact">Contact for details</Link></p>
        </div>
    );
}

const NeiloDescription = () => {
    return (
        <div className="text-lg my-4">
            Educational background:
            <br />
            Finished high school in Monta Vista High School, Cupertino
            <br />
            Currently in my last semester of undergrad in Computer Science, Mathematics at University of Wisconsin - Madison
            <br />

            Teaching experience (Computer Science, Competitive Programming, Math): 4 years
            <br />

            <br />

            <br />
            <p className="font-bold">Teaching style:</p>
            <ul className="list-inside list-disc">
                <li>
                    Everybody should have fun
                </li>
            </ul>

            <p className="font-bold">Subjects taught:</p>
            <ul className="list-inside list-disc">
                <li>
                    Java
                </li>
                <li>
                    AP Computer Science A
                </li>
                <li>
                    AP Statistics
                </li>
                <li>
                    College level linear algebra, multivariable calculus
                </li>
            </ul>

            <br />
            Email: nikhilc1527@gmail.com
            <br />
            Phone: (650) 495-9921

            <br />
            <br />
            <p className="text-2xl text-blue-500"><Link to="/contact">Contact for details</Link></p>
        </div>
    );
}

const TeacherSection = ({ subjects, name, img, desc }) => {
    return (
        <div className="flex flex-col bg-gray-100 mb-16">
            <div className="flex items-center justify-end">
                <div
                    className="flex flex-wrap justify-center p-4">
                    {subjects.map((subject, index) => (
                        <div key={index} className="w-full h-full md:w-1/3 px-2 mb-4 hover:scale-110 transform transition duration-300">
                            <Card title={subject.label} description={subject.value} />
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-16 p-8 rounded-lg">
                <h2 className="text-4xl font-bold mb-4 text-center">About {name}</h2>
                <div className="flex flex-col md:flex-row items-center">
                    <div className="md:w-1/3 mb-4 md:mb-0">
                        <img src={img} alt="Teacher" className="w-full h-auto object-cover rounded-lg" />
                    </div>
                    <div className="md:w-2/3 md:pl-8">
                        {desc}
                    </div>
                </div>
            </div>
        </div>
    );
}

const Home = () => {
    const navigate = useNavigate();

    const handleStudentLoginClick = () => {
        navigate('/login/student');
    };
    const handleTeacherLoginClick = () => {
        navigate('/login/teacher');
    };

    return (
        <div className="bg-white min-h-screen">
            <div className="container mx-auto p-8">
                <div className="container mx-auto p-8 flex justify-center mb-16">
                    <div className="flex flex-col md:flex-row items-center md:items-start">
                        <div className="md:w-2/3 flex flex-col items-center md:items-start md:pr-8">
                            <p className="text-5xl font-bold mb-4 text-center md:text-left">AP Guidance</p>
                            <p className="text-lg text-center md:text-left">Join classes to learn about physics, math, and computer science!</p>
                        </div>
                        <div className="md:w-40 mt-8 md:mt-0">
                            <img src={atomImg} alt="Descriptive Alt Text" className="w-full h-auto object-cover" />
                        </div>
                    </div>
                </div>

                <TeacherSection subjects={subjects_baba} name={"Nilanjan"} img={teacherImage} desc={<BabaDescription />}/>
                {/* {!PROD && (<TeacherSection subjects={subjects_neilo} name={"Nikhil"} img={neiloImage} desc={<NeiloDescription />}/>)} */}

                <Testimonials />
                
                {!PROD && (<div className="flex justify-center mt-20 space-x-4">
                               <button
                                   className="bg-blue-500 text-white font-bold py-4 px-8 rounded-lg mx-1/3"
                                   onClick={handleStudentLoginClick}
                               >
                                   I'm a student
                                   </button>
                                   <button
                                       className="bg-blue-500 text-white font-bold py-4 px-8 rounded-lg mx-1/3"
                                       onClick={handleTeacherLoginClick}
                                   >
                                       I'm a teacher
                                   </button>
                               <button
                                   className="bg-blue-500 text-white font-bold py-4 px-8 rounded-lg mx-1/3"
                                   onClick={() => { navigate("/contact") }}
                               >
                                   I want to be a student
                               </button>
                           </div>)}
        </div>
        </div>
    );
};

export default Home;
