import React from 'react';
import { Link } from 'react-router-dom';

const ProblemCard = ({ problem }) => {
  return (
    <div className="border border-gray-300 p-4 rounded-lg mb-4 shadow-sm">
      <h3 className="text-xl font-bold mb-2">{problem.title}</h3>
      <Link
        to={`/problem/${problem.id}`}
        className="text-blue-500 hover:underline"
      >
        View Problem
      </Link>
    </div>
  );
};

export default ProblemCard;
