// src/App.js
import React, { StrictMode, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/Home.js'
import Contact from './pages/Contact.js'
import Page404 from './pages/404.js';
import Login from './pages/Login.js';
import ProbremerPortal, { problemer_loader } from './pages/ProblemerPortal.js';
import NewProblemPage from './pages/NewProblemPage.js';
import ProblemPage, { problem_loader } from './pages/ProblemPage.js';
import EditProblemPage from './pages/EditProblemPage.js';
import UserPortal, { user_portal_loader } from './pages/UserPortal.js';
import Root from './Root.js';
import Authorize, { auth_loader } from './pages/Authorize.js';
import Oauth2Callback, { oauth_loader } from './pages/Oauth2Callback.js';
import StudentPortal, { student_portal_loader } from './pages/StudentPortal.js';
import AdminPortal, { admin_loader } from './pages/AdminPortal.js';
import { check_loggedin } from './util/server_util.js';
import PermissionsTab, { permissions_tab_loader } from './pages/PermissionsTab.js';
import CalendarTab, { calendar_tab_loader } from './pages/CalendarTab.js';

export const PROD = true;

export const GOOGLE_CLIENT_ID = "698750911506-f2mj9ah4mfb5sjj0girukrrft2mbaqbf.apps.googleusercontent.com";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Home />,
                index: true,
            },
            {
                path: "contact",
                element: <Contact />
            },
            /*!PROD && */{
                path: "login",
                element: <Login />
            },
            /*!PROD && */{
                path: "problems",
                element: <ProbremerPortal />,
                loader: problemer_loader
            },
            /*!PROD && */{
                path: "admin",
                element: <AdminPortal />,
                loader: admin_loader,
                children: [
                    {
                        path: "permissions",
                        element: <PermissionsTab />,
                        loader: permissions_tab_loader
                    },
                    {
                        path: "calendar",
                        element: <CalendarTab />,
                        loader: calendar_tab_loader
                    }
                ]
            },
            /*!PROD && */{
                path: "new-problem",
                element: <NewProblemPage />
            },
            /*!PROD && */{
                path: "edit-problem/:prob_id",
                element: <EditProblemPage />
            },
            /*!PROD && */{
                path: "problem/:prob_id",
                element: <ProblemPage />,
                loader: problem_loader
            },
            /*!PROD && */{
                path: "user-portal/:status",
                element: <UserPortal />,
                loader: user_portal_loader
            },
            /*!PROD && */{
                path: "user-portal",
                element: <UserPortal />,
                loader: user_portal_loader
            },
            /*!PROD && */{
                path: "student",
                element: <StudentPortal />,
                loader: student_portal_loader
            },
            /*!PROD && */{
                path: "authorize/:scope",
                element: <Authorize />,
                loader: auth_loader
            },
            /*!PROD && */{
                path: "oauth2callback",
                element: <Oauth2Callback />,
                loader: oauth_loader
            },
            {
                path: "404",
                element: <Page404 />
            },
            {
                path: "*",
                element: <Page404 />
            }
        ]
    }
]);

export const LoginContext = React.createContext();

const App = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        if (loggedIn) return;
        check_loggedin().then(() => {
            setLoggedIn(true);
        }).catch(() => {});
    });

    return (
        <StrictMode>
            <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
                <RouterProvider router={router} />
            </LoginContext.Provider>
        </StrictMode>
    );
}

export default App;
