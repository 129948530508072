import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { LoginContext } from '../App';
import { logout } from '../util/server_util';

const Navbar = () => {
    const {loggedIn, setLoggedIn} = useContext(LoginContext);
    const isActiveF = ({ isActive }) => isActive ? 'text-white font-bold' : 'text-white';
    const navigate = useNavigate();

    const userportal = loggedIn ? (
        <NavLink
            to="/user-portal/ok"
            className={isActiveF}
        >
            User Portal
        </NavLink>
    ) : (
        <></>
    );

    const element = loggedIn ? (
        <p
            className="text-white cursor-pointer"
            onClick={() => {
                logout().then(() => {
                    setLoggedIn(false);
                    navigate("/login");
                }).catch(() => {});
            }}
        >
            Logout
        </p>
    ) : (
        <NavLink
            to="/login"
            className={isActiveF}
        >
            Login
        </NavLink>
    );

    return (
        <nav className="bg-bg1 p-4 sticky top-0 z-50 h-64px">
            <div className="container mx-auto flex justify-between items-center">
                <NavLink to="/" className="text-white text-lg font-bold">AP Guidance</NavLink>
                <div className="flex overflow-x-auto whitespace-nowrap space-x-4">
                    <div className="inline-flex space-x-4 justify-end">
                        <NavLink
                            to="/"
                            className={isActiveF}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to="/contact"
                            className={isActiveF}
                        >
                            Contact
                        </NavLink>
                        {/* {userportal} */} 
                        {/* {element} */} 
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
