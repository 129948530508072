import { useLoaderData, useNavigate } from "react-router-dom";
import MyCalendar from "../components/Calendar";
import { get_events } from "../util/server_util";
import { useState } from "react";

const AuthButton = ({ authorize_cal }) => {
    return (
        <button
            className="bg-blue-500 text-3xl text-white rounded-lg p-4 mb-4"
            onClick={authorize_cal}
        >
            AUTHORIZE CALENDAR
        </button>
    );
}

const CalendarTab = () => {
    const response = useLoaderData();
    const navigate = useNavigate();
    let [calName, setCalName] = useState(response === null ? null : response.myname);
    const authorize_cal = async () => {
        try {
            navigate("/authorize/b");
        }
        catch (e) {
            console.log(e);
            alert("could not get permissions to calendar")
        }
    };
    if (response === null) {
        return <AuthButton authorize_cal={authorize_cal} />;
    }
    console.log("calname:", calName);
    const { names, myname, self_events, events } = response;



    let showEvents = events[calName];

    return (
        <div className="mb-4">
            <select
                value={calName}
                onChange={(e) => {
                    console.log("selected:", e.target);
                    setCalName(e.target.value);
                }}
                className="w-fit p-2 border border-gray-300 rounded-lg mb-4"
            >
                {names.map((name) => (
                    <option key={name} value={name}>{name}</option>
                ))}
            </select>
            <div className="container mb-4">
                <MyCalendar showEvents={showEvents.events} />
            </div>
        </div>
    );

    // return (

    // );
}

export default CalendarTab;

export const calendar_tab_loader = async () => {
    try {
        const response = await get_events();

        const events = response.data.all_events;

        console.log("events:", events);

        let names = [];
        let myname = null;
        let self_events = null;
        for (const name in events) {
            names.push(name);
            if (events[name].self) {
                myname = name;
                self_events = events[name];
            }
        }
        if (!self_events) {
            return null;
        }

        const res = { names: names, myname: myname, self_events: self_events, events: events };

        console.log("res:", res);

        return res;
    }
    catch (e) {
        console.error(e);
        alert("calendar tab loading failed");
        return null;
    }
}
