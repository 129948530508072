import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useCallback, useRef, useState } from "react";
import { check_loggedin, delete_event, get_calnames, get_events_startdate, new_event, update_event } from "../util/server_util";
import { redirect, useLoaderData } from "react-router-dom";

const Modal = ({ closeModal, event, teacher, refresh_cal, revert }) => {
    const [title, setTitle] = useState(event.title);
    const [description, setDescription] = useState(event.extendedProps.old_event_obj.description);

    console.log("event:", event);
    console.log("title:", title);
    console.log("description:", description);
    console.log("revert:", revert);

    const outsideClick = (e) => {
        if (e.target.className.includes('modal-overlay')) {
            if (revert) revert();
            closeModal();
        }
    };

    const handleSubmit = async () => {
        // alert(`Title: ${title}\nDescription: ${description}`);
        const body = {
            summary: title,
            description: description,
            start_str: event.startStr,
            end_str: event.endStr,
            teacher: teacher,
            id: event.id
        };
        await update_event(body);
        refresh_cal();
        closeModal();
    };

    console.log("hangout link:", event.extendedProps.old_event_obj.hangoutLink);

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 modal-overlay z-50"
            onClick={outsideClick}
        >
            <div className="bg-white p-6 rounded-lg text-center z-60 align-center justify-center">

                <button className="m-8 px-4 py-2 bg-blue-400 rounded-lg" onClick={() => window.open(event.extendedProps.old_event_obj.hangoutLink)}>
                    LINK TO GOOGLE MEET
                </button>

                <div className="py-8 border-y-4">
                    <h2 className="text-2xl font-semibold mb-4">Update Information</h2>
                    <div className="mb-4">
                        <label className="block text-left text-gray-700 mb-2" htmlFor="title">
                            Title:
                        </label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-left text-gray-700 mb-2" htmlFor="description">
                            Description:
                        </label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows="4"
                        ></textarea>
                    </div>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    >
                        Update
                    </button>
                </div>

                <button className="m-8 px-4 py-2 bg-red-400 rounded-lg" onClick={async () => {
                            await delete_event({ id: event.extendedProps.old_event_obj.id, teacher: teacher });
                            closeModal();
                            refresh_cal();
                        }}>
                    Delete Event
                </button>
            </div>
        </div>
    );
}

const Loader = () => {
    return (
        <div className='flex space-x-2 items-center'>
            <span className='sr-only'>Loading...</span>
            <div className='h-4 w-4 rounded-full bg-green-500 animate-bounce [animation-delay:-0.3s]'></div>
            <div className='h-4 w-4 rounded-full bg-green-500 animate-bounce [animation-delay:-0.15s]'></div>
            <div className='h-4 w-4 rounded-full bg-green-500 animate-bounce'></div>
        </div>
    );
}

const StudentPortal = () => {
    const [isRecurring, setIsRecurring] = useState(false);
    const [comments, setComments] = useState('');
    const calnames = useLoaderData();
    const [calName, setCalName] = useState(calnames[0]);
    const calendarRef = useRef(null);
    const [curSelection, setCurSelection] = useState([]);
    const [memoMap, setMemoMap] = useState({});
    const [showCalPrompt, setShowCalPrompt] = useState(false);
    const [calLoading, setCalLoading] = useState(false);
    const [calRefresh, setCalRefresh] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editInfo, setEditInfo] = useState(null);

    const refresh_cal = () => {
        setMemoMap({});
        setCalRefresh(a => !a);
    }

    const openModal = (info) => {
        setEditInfo(info);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setEditInfo(null);
        setIsModalOpen(false);
    };

    const handleConfirm = async () => {
        console.log("submitting event:", curSelection);
        console.log('Event Recurring:', isRecurring);
        console.log('Additional Comments:', comments);

        if (curSelection.startStr === undefined) {
            setShowCalPrompt(true);
        }
        else {
            try {
                await new_event({ teacher: calName, start_time: curSelection.startStr, end_time: curSelection.endStr, recurring: isRecurring, comments: comments });
                setIsRecurring(false);
                setComments("");
                setCurSelection([]);
                calendarRef.current.getApi().unselect();

                refresh_cal();
            } catch (e) {
                alert("there was an error submitting, please try again");
                console.error(e);
            }
        }
    };

    const get_event_objs = (res) => {
        let event_objs = [];

        for (const event of res.events[calName.email].events) {
            if (event.self) {
                console.log(event);
                event_objs.push({ "start": event['start'], "end": event["end"], "title": event.summary, "editable": true, "color": "blue", "textColor": "white", "overlap": false, "id": event.id, "old_event_obj": event });
            }
            else {
                event_objs.push({ "start": event['start'], "end": event["end"], "editable": false, "color": "gray", "textColor": "white", "overlap": false });
            }
        }
        return event_objs;
    }

    const selectionCallback = (selectionInfo) => {
        setCurSelection(selectionInfo);
        if (showCalPrompt) setShowCalPrompt(false);
    }
    const unSelectionCallback = () => {
        setCurSelection([]);
    }
    const loadingCallback = (loading) => {
        setCalLoading(loading);
    }
    const eventClickCallback = (info) => {
        console.log('click info:', info);
        openModal(info);
    }
    const dropCallback = (info) => {
        openModal(info);
    }

    const event_source = async (info, success, failure) => {
        const memo_str = info.startStr + calName.email;
        if (memoMap[memo_str] === undefined) {
            console.log("fetching events");
            const events_response = await get_events_startdate(info.startStr, calName.email);
            const events_response_parsed = parse_events_response(events_response);
            const event_objs = get_event_objs(events_response_parsed);
            let new_map = memoMap;
            new_map[memo_str] = event_objs;
            setMemoMap(new_map);
        }
        success(memoMap[memo_str]);
    }

    const event_source_memo = useCallback(event_source, [calName, calRefresh]);

    return (
        <div className="mx-auto py-4 flex flex-col container h-full justify-start">
            <h1 className="text-3xl font-bold">Schedule Events with {calName.name}</h1>

            <select
                value={calName.name}
                onChange={(e) => {
                    setCalName(calnames[e.target.options.selectedIndex]);
                }}
                className="w-fit p-2 border border-blue-800 rounded-lg bg-blue-200 my-4 border-2"
            >
                {calnames.map((teacher) => (
                    <option key={teacher.email} value={teacher.name}>{teacher.name}</option>
                ))}
            </select>

            {calLoading && (
                <Loader />
            )}

            <div className="overflow-y-auto my-4">
                <FullCalendar ref={calendarRef}
                    plugins={[timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: ''
                    }}
                    initialView='timeGridWeek'
                    weekends={true}
                    nowIndicator={true}
                    allDaySlot={false}

                    selectable={true}
                    selectMirror={true}
                    unselectAuto={false}
                    selectOverlap={false}
                    select={selectionCallback}
                    unselect={unSelectionCallback}

                    events={event_source_memo}
                    loading={loadingCallback}
                              
                    eventClick={eventClickCallback}
                    eventDrop={dropCallback}
                />
            </div>
            {showCalPrompt &&
                (
                    <h1 className="text-red-500 text-bold text-2xl">
                        Please select a time in the calendar
                    </h1>
                )
            }
            <button className="bg-red-200 hover:bg-red-300 my-2 p-2 rounded-lg w-fit" onClick={() => calendarRef.current.getApi().unselect()}>
                Clear Selection
            </button>
            <div className="mt-4">
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Is this a recurring event?</label>
                    <select
                        value={isRecurring}
                        onChange={(e) => setIsRecurring(e.target.value === 'true')}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Additional Comments</label>
                    <textarea
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        rows="4"
                        placeholder="Enter any additional details here..."
                    ></textarea>
                </div>
                <button
                    onClick={handleConfirm}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Confirm
                </button>
            </div>

            {isModalOpen && (
                <Modal
                    closeModal={closeModal}
                    event={editInfo.event}
                    teacher={calName}
                    refresh_cal={refresh_cal}
                    revert={editInfo.revert}
                />
            )}

        </div>
    );
}

export default StudentPortal;

const parse_events_response = (response) => {
    const events = response.data.all_events;

    let names = [];
    for (const name in events) {
        names.push(name);
    }

    const res = { names: names, events: events };

    return res;
}

export const student_portal_loader = async () => {
    try {
        const perms_request = await check_loggedin();
        const perms = perms_request.data.perms;
        if (!perms.includes('student')) {
            return redirect("/user-portal");
        }
        const calnames = await get_calnames();
        return calnames.data;
    }
    catch (e) {
        return redirect("/login");
    }
}
