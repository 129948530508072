import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();

    return (
        <div className="container flex mx-auto h-full items-center justify-center">
            <button
                onClick={() => navigate("/authorize/a")}
                className="bg-blue-500 text-white font-bold p-12 rounded-xl text-5xl"
            >
                Sign In With Google
            </button>
        </div>
    );

};

export default Login;
