import React, { useState } from 'react';

import 'katex/dist/katex.min.css'; // Import KaTeX CSS for styling
import { newProblem } from '../util/server_util';
import InlineLatex from '../components/InlineLatex';
import { useNavigate } from 'react-router-dom';

const NewProblemPage = () => {
    const [title, setTitle] = useState('');
    const [problemText, setProblemText] = useState('');
    const [solutionText, setSolutionText] = useState('');
    const [problemImages, setProblemImages] = useState([]);
    const [solutionImages, setSolutionImages] = useState([]);
    const [difficulty, setDifficulty] = useState(0);
    const [answer, setAnswer] = useState(0);
    const [units, setUnits] = useState('');

    const navigate = useNavigate();

    const handleProblemImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setProblemImages(files);
    };

    const handleSolutionImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setSolutionImages(files);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        let formData = new FormData();
        formData.append("title", title);
        formData.append("problemText", problemText);
        formData.append("solutionText", solutionText);
        formData.append("difficulty", difficulty);
        formData.append("answer", answer);
        formData.append("units", units);

        problemImages.forEach((file, index) => {
            formData.append(`problemImages_${index}`, file);
        });

        solutionImages.forEach((file, index) => {
            formData.append(`solutionImages_${index}`, file);
        });
        newProblem(
            formData
        ).then(() => {
            navigate("/problems");
        }
        );
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-3xl font-bold mb-8">Create New Problem</h1>

            <form onSubmit={handleSubmit}>
                <div className="mb-8">
                    <label className="block text-lg font-medium mb-2" htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={title}
                        required
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div>

                <div className="mb-8">
                    <label className="block text-lg font-medium mb-2" htmlFor="problemText">Problem Text</label>
                    <textarea
                        id="problemText"
                        name="problemText"
                        value={problemText}
                        required
                        onChange={(e) => setProblemText(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                    />

                    <InlineLatex text={problemText}/>
                    
                    <label className="block text-lg font-medium mb-2" htmlFor="problemImageUpload">Problem Image Uploads</label>
                    <input
                        type="file"
                        id="problemImageUpload"
                        name="problemImageUpload"
                        multiple
                        accept=".png,.jpg,.jpeg"
                        onChange={handleProblemImageUpload}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div>

                <div className="mb-8 flex justify-left">
                    <div className="mr-8">
                        <label className="text-lg font-medium mb-2">Answer: </label>
                        <input
                            type="number"
                            id="answer"
                            name="answer"
                            className="p-2 border-b-2 border-slate-500"
                            value={answer}
                            required
                            onChange={e => setAnswer(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="text-lg font-medium mb-2">Units: </label>
                        <input
                            type="text"
                            id="answer"
                            name="answer"
                            className="p-2 border-b-2 border-slate-500"
                            value={units}
                            onChange={e => setUnits(e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-8">
                    <label className="block text-lg font-medium mb-2" htmlFor="solutionText">Solution Explanation Text</label>
                    <textarea
                        id="solutionText"
                        name="solutionText"
                        value={solutionText}
                        required
                        onChange={(e) => setSolutionText(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                    />

                    <InlineLatex text={solutionText} />
                    
                    <label className="block text-lg font-medium mb-2" htmlFor="solutionImageUpload">Solution Image Uploads</label>
                    <input
                        type="file"
                        id="solutionImageUpload"
                        name="solutionImageUpload"
                        multiple
                        accept=".png,.jpg,.jpeg"
                        onChange={handleSolutionImageUpload}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div>

                <div className="mb-8">
                    <label className="block text-lg font-medium mb-2">Difficulty Rating (1-5)</label>
                    <div className="flex space-x-4">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <label key={value} className="flex items-center">
                                <input
                                    type="radio"
                                    name="difficulty"
                                    value={value}
                                    checked={difficulty === value}
                                    onChange={(e) => setDifficulty(parseInt(e.target.value))}
                                    className="form-radio h-4 w-4 text-blue-600"
                                />
                                <span className="ml-2">{value}</span>
                            </label>
                        ))}
                    </div>
                </div>

                <button
                    type="submit"
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default NewProblemPage;
