// src/pages/Contact.js
import React, { useState } from 'react';
import subjects from '../static/subjects_baba.json';

import Select from 'react-select'
import { useNavigate } from 'react-router-dom';
import { addNewStudent } from '../util/server_util';
import useDocumentTitle from '../util/doc_title';

const Confirmation = () => {
    const navigate = useNavigate();

    return (
        <div className="container mx-auto p-8 text-center">
            <h2 className="text-3xl font-bold mb-4">Thank You!</h2>
            <p className="mb-8">Your contact information has been submitted successfully.</p>
            <button
                onClick={() => navigate('/')}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
            >
                Go to Home
            </button>
        </div>
    );
};

const Contact = () => {
    useDocumentTitle("Contact");

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        schoolGrade: '',
        lastPhysicsClass: '',
        lastMathClass: '',
        subjects: [],
        otherSubject: '',
        mathCompetency: '',
        physicsCompetency: '',
        classType: '',
        lessonType: '',
    });
    
    const [submitted, setSubmitted] = useState(false);
    const [showPhysicsComp, setShowPhysicsComp] = useState(false);
    const [showMathComp, setShowMathComp] = useState(false);
    const [showOtherSubject, setShowOtherSubject] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    if (submitted) {
        return <Confirmation />;
    }

    if (formData.lastPhysicsClass.length > 0 && !showPhysicsComp) setShowPhysicsComp(true);
    if (formData.lastMathClass.length > 0 && !showMathComp) setShowMathComp(true);

    if (formData.lastPhysicsClass.length === 0 && showPhysicsComp) {
        setShowPhysicsComp(false);
        setFormData({...formData, physicsCompetency: ''});
    };
    if (formData.lastMathClass.length === 0 && showMathComp) {
        setShowMathComp(false);
        setFormData({...formData, mathCompetency: ''});
    };

    if (formData.subjects.includes("Other") && !showOtherSubject) setShowOtherSubject(true);
    if (!formData.subjects.includes("Other") && showOtherSubject) {
        setShowOtherSubject(false);
        setFormData({...formData, otherSubject: ''});
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (e) => {
        setFormData({
            ...formData,
            "subjects": e.map((x) => {
                return x.label;
            }),
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        
        try {
            addNewStudent(formData).then((res) => {
                setSubmitted(true);
            }).catch((e) => {
                alert("there was an error submitting the contact request. please reach out to nilanjan directly at nilanjan@apguidance.com or try again later");
            });
        } catch (e) {
            alert("there was an error submitting the contact request. please reach out to nilanjan directly at nilanjan@apguidance.com or try again later")
        }
    };

    return (
        <div className="container mx-auto p-8">
            <h2 className="text-3xl font-bold mb-4">Contact Me</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="phone">Phone</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="schoolGrade">School Grade for 2024-2025 School Year</label>
                    <select
                        id="schoolGrade"
                        name="schoolGrade"
                        value={formData.schoolGrade}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg bg-white"
                        required
                    >
                        <option value="">Select Grade</option>
                        {Array.from({ length: 7 }, (_, i) => i + 6).map((grade) => (
                            <option key={grade} value={grade}>
                                {grade}th
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="lastPhysicsClass">Last Physics Class Taken</label>
                    <input
                        type="text"
                        id="lastPhysicsClass"
                        name="lastPhysicsClass"
                        value={formData.lastPhysicsClass}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div>
                {showPhysicsComp && (
                    <div className="mb-4">
                        <label className="block text-lg font-medium mb-2">Physics Competency (1-5)</label>
                        <div className="flex space-x-4">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <label key={value} className="flex items-center">
                                    <input
                                        type="radio"
                                        name="physicsCompetency"
                                        value={value}
                                        checked={formData.physicsCompetency === String(value)}
                                        onChange={handleChange}
                                        className="form-radio h-4 w-4 text-blue-600"
                                    />
                                    <span className="ml-2">{value}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                )}

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="lastMathClass">Last Math Class Taken</label>
                    <input
                        type="text"
                        id="lastMathClass"
                        name="lastMathClass"
                        value={formData.lastMathClass}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div>
                {showMathComp && (
                    <div className="mb-4">
                        <label className="block text-lg font-medium mb-2">Math Competency (1-5)</label>
                        <div className="flex space-x-4">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <label key={value} className="flex items-center">
                                    <input
                                        type="radio"
                                        name="mathCompetency"
                                        value={value}
                                        checked={formData.mathCompetency === String(value)}
                                        onChange={handleChange}
                                        className="form-radio h-4 w-4 text-blue-600"
                                    />
                                    <span className="ml-2">{value}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                )}

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Preferred Class Type</label>
                    <div className="flex space-x-4 mb-2">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="classType"
                                value="online"
                                checked={formData.classType === 'online'}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-blue-600"
                            />
                            <span className="ml-2">Online</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="classType"
                                value="in person"
                                checked={formData.classType === 'in person'}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-blue-600"
                            />
                            <span className="ml-2">In Person</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="classType"
                                value="both"
                                checked={formData.classType === 'both'}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-blue-600"
                            />
                            <span className="ml-2">Either/Hybrid</span>
                        </label>
                    </div>
                    <p className="block text-medium font-medium mb-2">Note: in person classes are done in Cupertino, CA</p>
                </div>
                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2">Preferred Lesson Type</label>
                    <div className="flex space-x-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="lessonType"
                                value="private"
                                checked={formData.lessonType === 'private'}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-blue-600"
                            />
                            <span className="ml-2">Private</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="lessonType"
                                value="group"
                                checked={formData.lessonType === 'group'}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-blue-600"
                            />
                            <span className="ml-2">Group</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="lessonType"
                                value="either"
                                checked={formData.lessonType === 'either'}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-blue-600"
                            />
                            <span className="ml-2">Either</span>
                        </label>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="subjects">Subjects interested in</label>
                    <Select
                        defaultValue={[]}
                        isMulti
                        name="subjects"
                        options={subjects.concat([{label: "Other", value: "other"}])}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        classNamePrefix="select"
                        required
                        onChange={handleMultiSelectChange}
                    />
                </div>
                {showOtherSubject && (
                    <div className="mb-4">
                        <label className="block text-lg font-medium mb-2" htmlFor="otherSubject">Please specify other subjects</label>
                        <input
                            type="text"
                            id="otherSubject"
                            name="otherSubject"
                            value={formData.otherSubject}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                    </div>
                )}

                <div className="mb-4">
                    <label className="block text-lg font-medium mb-2" htmlFor="specialNotes">Special Notes</label>
                    <textarea
                        id="specialNotes"
                        name="specialNotes"
                        value={formData.specialNotes}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-lg resize-none"
                    />
                </div>

                <div className="text-center">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
                        disabled={submitting}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Contact;
