// src/components/Card.js
import React from 'react';

const Card = ({ title, description }) => {
    return (
        <div className="bg-bg3 hover:bg-bg3-bright shadow-md rounded-lg p-4 m-2 flex-grow h-full">
            <h3 className="text-xl font-bold mb-2">{title}</h3>
            <p className="text-sm">{description}</p>
        </div>
    );
}

export default Card;
