import { useState } from "react";
import { NavLink, Outlet, redirect } from "react-router-dom";

const AdminNavBar = () => {
    const isActiveF = ({ isActive }) => "px-4 py-2 " + (isActive ? 'border-b-2 border-blue-500 font-bold' : '');
    return (
        <nav className="flex mb-4 border-b justify-self-start justify-between items-center">
            <div className="flex">
                <NavLink
                    className={isActiveF}
                    to="/admin/permissions"
                >
                    Permissions
                </NavLink>

                <NavLink
                    className={isActiveF}
                    to="/admin/calendar"
                >
                    Calendar
                </NavLink>
            </div>
        </nav>
    );
};

const AdminPortal = () => {
    const [selectedTab, setSelectedTab] = useState('permissions');

    return (
        <div className="container mx-auto p-8 flex-col items-stretch flex-1 h-[calc(100vh-60px)]">
            <AdminNavBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <div className="flex-1 justify-self-stretch">
                <Outlet />
            </div>
        </div>
    );
}

export default AdminPortal;

export const admin_loader = async ({ request }) => {
    const url = URL.parse(request.url);
    if (url.pathname === '/admin' || url.pathname === '/admin/')
        return redirect("/admin/permissions");
    return null;
}
