import React, { useContext } from 'react';
import { useNavigate, useLoaderData, redirect } from 'react-router-dom';
import { check_loggedin, logout } from '../util/server_util';
import { LoginContext } from '../App';

const permissionRoutes = {
    student: '/student',
    teacher: '/teacher',
    problemers: '/problems',
    admin: '/admin',
};

const UserPortal = () => {
    const data = useLoaderData();
    const navigate = useNavigate();
    const { setLoggedIn } = useContext(LoginContext);

    const error_message = (message, redirect, shouldLogout) => {
        setLoggedIn(false);
        return (
            <div className="flex flex-col align-center justify-begin">
                <p className="font-bold py-2 px-4 m-2">
                    {message}
                </p>
                <button
                    onClick={
                        async () => {
                            if (shouldLogout) {
                                await logout();
                            }
                            navigate(redirect);
                        }
                    }
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg m-2"
                >
                    Go Back
                </button>
            </div>
        );
    }

    const renderButtons = () => {
        console.log("data:", data);
        if (data.status === undefined) {
            console.log("no status");
            return error_message("Need to log in again", "/login", false);
        }
        else if (data.status === "oauth2") {
            return error_message("There was an error logging in. Please try again.", "/login", false);
        }
        else if (data.status === "dne") {
            return error_message("You do not have an account. Please tell nilanjan@apguidance.com to add you an account", "/", true);
        }
        const permissions = data.perms;
        console.log("perms:", permissions);
        if (permissions.length === 0) {
            return (
                <div className="flex flex-col align-center justify-begin">
                    <p className="font-bold py-2 px-4 m-2">
                        You have no permissions
                    </p>
                    <button
                        onClick={
                            async () => {
                                await logout();
                                setLoggedIn(false);
                                navigate("/");
                            }
                        }
                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg m-2"
                    >
                        Go Home
                    </button>
                </div>
            );
        }
        else {
            return permissions.map((permission) => (
                <button
                    key={permission}
                    onClick={() => navigate(permissionRoutes[permission])}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg m-2"
                >
                    {permission.charAt(0).toUpperCase() + permission.slice(1)} Portal
                </button>
            ));
        }
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-3xl font-bold mb-8">User Portal</h1>
            <div className="flex flex-wrap justify-center">
                {renderButtons()}
            </div>
        </div>
    );
};

export default UserPortal;

export const user_portal_loader = async ({ params }) => {
    const status = params.status;
    console.log("params:", params);
    if (status === undefined || status === "ok") {
        let res;
        try {
            const perms_request = await check_loggedin();
            const perms = perms_request.data.perms;
            console.log("got perms", perms);
            if (perms.length === 1) {
                return redirect(permissionRoutes[perms[0]]);
            }
            else
                res = perms;
        }
        catch (e) {
            console.error(e);
            res = [];
        }
        return { status: status, perms: res };
    }
    else {
        console.log("there was error: ", status);
        return params;
    }
}
