import { redirect } from "react-router-dom";
import { authorize } from "../util/server_util";

const Authorize = () => {
    return (
        <div>
            AUTHORIZING
        </div>
    );
}

export default Authorize;

export const auth_loader = async ({request, params}) => {
    const url = new URL(request.url);
    let ref = url.protocol + "//" + url.host + "/oauth2callback";
    try {
        let redirect_url = await authorize(ref, params.scope);
        return redirect(redirect_url.data.authorization_url);
    }
    catch (e) {
        console.log(e);
        return e;
    }
}
