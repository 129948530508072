import axios from "axios"

import env from '../static/env.json'

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: env.api_url
})

export const check_loggedin = async () => {
    return axiosInstance.get("/loggedin");
}

export const add_user = async (email) => {
    return axiosInstance.post(
        "/new-user",
        {email: email}
    );
}
export const delete_user = async (email) => {
    return axiosInstance.post(
        "/del-user",
        {email: email}
    );
}

export const update_user_permissions = async (user, perms) => {
    return axiosInstance.post(
        "/update-user-permissions",
        {user: user, perms: perms}
    );
}

export const get_calnames = async () => {
    return axiosInstance.get("/calnames");
}

export const new_event = async (body) => {
    return axiosInstance.post("new-event", body);
}

export const delete_event = async (body) => {
    return axiosInstance.post("delete-event", body);    
}
export const update_event = async (body) => {
    return axiosInstance.post("update-event", body);    
}

export const get_events = async () => {
    return axiosInstance.get("get-events/admin");
}
export const get_events_startdate = async (start_time, teacher_email) => {
    return axiosInstance.get("get-events/student", {params: {start_time: start_time, teacher_email: teacher_email}});
}
export const get_events_student = async () => {
    return axiosInstance.get("get-events/student");
}

export const get_users_permissions = async () => {
    return axiosInstance.get("/users-permissions");
}

export const authorize = async (redirect_url, scope) => {
    return axiosInstance.get("/authorize", {
        params: {redirect_url: redirect_url, scope: scope}
    });
}

export const oauth2callback = async (params) => {
    return axiosInstance.get("/oauth2callback", {params: params});
}

export const getProblems = async () => {
    return axiosInstance.get("/get-problems");
}

export const getProblem = async (prob_id) => {
    return axiosInstance.get("/problem/" + prob_id);
}

export const logout = async () => {
    return axiosInstance.get("/logout");
}

export const getImage = async (id) => {
    return axiosInstance.get(`/get-image/${id}`, { responseType: 'blob' });
}

export const deleteProblem = async (id) => {
    return axiosInstance.delete(`/delete-problem/${id}`);
}

export const newProblem = async (formData) => {
    return axiosInstance.post(
        "/new-problem",
        formData
    );
}
export const editProblem = async (id, formData) => {
    // console.log(id);
    // console.log(formData);
    return axiosInstance.put(
        `/edit-problem/${id}`,
        formData
    );
}

export async function addNewStudent(formData) {
    return axiosInstance.post("/new-student", {
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*'
        },
        data: JSON.stringify({ formData: formData }),
    });
}
