import React from 'react';

import 'katex/dist/katex.min.css'; // Import KaTeX CSS for styling
import katex from 'katex';

const renderInlineLatex = (text) => {
    const parts = text.split(/(\$[^\$]+\$)/g);

    return parts.map((part, index) => {
        if (parts === "") return (<> </>);
        if (part.startsWith('$') && part.endsWith('$')) {
            const latex = part.slice(1, -1); // Remove the $ delimiters
            try {
                return (
                    <span
                        key={index}
                        dangerouslySetInnerHTML={{ __html: katex.renderToString(latex, { throwOnError: false }) }}
                    />
                );
            } catch (error) {
                console.error('Error rendering LaTeX:', error);
                return <span key={index}>{part}</span>;
            }
        } else {
            // Replace newlines with <br> tags
            const partSplit = part.split('\n');
            const textWithLineBreaks = partSplit.map((line, i) => {
                if (i < partSplit.length - 1) {
                    return (
                        <React.Fragment key={i}>
                            {line}
                            <br />
                        </React.Fragment>
                    );
                }
                else {
                    return (
                        <React.Fragment key={i}>
                            {line}
                        </React.Fragment>
                    );
                }
            });
            return <span key={index}>{textWithLineBreaks}</span>;
        }
    });
};

const InlineLatex = ({text}) => {
    return (
        <div className="p-2 border border-gray-300 rounded-lg w-full bg-gray-50 mb-4">
            {renderInlineLatex(text)}
        </div>
    );
}

export default InlineLatex;
