import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InlineLatex from '../components/InlineLatex';
import { editProblem } from '../util/server_util';

const EditProblemPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const problem = location.state.problem; // Get the problem object from the router state

    const [title, setTitle] = useState(problem.title || '');
    const [problemText, setProblemText] = useState(problem.problemText || '');
    const [solutionText, setSolutionText] = useState(problem.solutionText || '');
    const [problemImages, setProblemImages] = useState([]);
    const [solutionImages, setSolutionImages] = useState([]);
    const [difficulty, setDifficulty] = useState(problem.difficulty || 3);
    const [uploadProblemImages, setUploadProblemImages] = useState(false);
    const [uploadSolutionImages, setUploadSolutionImages] = useState(false);

    const handleProblemImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setProblemImages(files);
    };

    const handleSolutionImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setSolutionImages(files);
    };

    const handleSubmit = async () => {
        const formData = new FormData();

        if (title !== problem.title) formData.append('title', title);
        if (problemText !== problem.problemText) formData.append('problemText', problemText);
        if (solutionText !== problem.solutionText) formData.append('solutionText', solutionText);
        if (difficulty !== problem.difficulty) formData.append('difficulty', difficulty);
        formData.append('uploadProblemImages', uploadProblemImages);
        formData.append('uploadSolutionImages', uploadSolutionImages);

        if (uploadProblemImages) {
            problemImages.forEach((file, index) => {
                formData.append(`problemImages_${index}`, file);
            });
        }

        if (uploadSolutionImages) {
            solutionImages.forEach((file, index) => {
                formData.append(`solutionImages_${index}`, file);
            });
        }

        try {
            await editProblem(problem._id, formData);
            navigate('/problems'); // Redirect to the problems list after successful update
        } catch (error) {
            console.error('Error updating problem:', error);
        }
    };


    return (
        <div className="container mx-auto p-8">
            <h1 className="text-3xl font-bold mb-8">Edit Problem</h1>

            <div className="mb-8">
                <label className="block text-lg font-medium mb-2" htmlFor="title">Title</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                />
            </div>

            <div className="mb-8">
                <label className="block text-lg font-medium mb-2" htmlFor="problemText">Problem Text</label>
                <textarea
                    id="problemText"
                    name="problemText"
                    value={problemText}
                    onChange={(e) => setProblemText(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                />
                <div className="p-2 border border-gray-300 rounded-lg w-full bg-gray-50 mb-4">
                    <InlineLatex text={problemText} />
                </div>
                <div className="mb-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={uploadProblemImages}
                            onChange={() => setUploadProblemImages(!uploadProblemImages)}
                            className="mr-2"
                        />
                        Upload new problem images? (This will delete the old ones)
                    </label>
                </div>
                {uploadProblemImages && (
                    <div className="mb-4">
                        <label className="block text-lg font-medium mb-2" htmlFor="problemImageUpload">Problem Image Uploads</label>
                        <input
                            type="file"
                            id="problemImageUpload"
                            name="problemImageUpload"
                            multiple
                            onChange={handleProblemImageUpload}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                    </div>
                )}
            </div>

            <div className="mb-8">
                <label className="block text-lg font-medium mb-2" htmlFor="solutionText">Solution Text</label>
                <textarea
                    id="solutionText"
                    name="solutionText"
                    value={solutionText}
                    onChange={(e) => setSolutionText(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                />
                <div className="p-2 border border-gray-300 rounded-lg w-full bg-gray-50 mb-4">
                    <InlineLatex text={solutionText} />
                </div>
                <div className="mb-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={uploadSolutionImages}
                            onChange={() => setUploadSolutionImages(!uploadSolutionImages)}
                            className="mr-2"
                        />
                        Upload new solution images? (This will delete the old ones)
                    </label>
                </div>
                {uploadSolutionImages && (
                    <div className="mb-4">
                        <label className="block text-lg font-medium mb-2" htmlFor="solutionImageUpload">Solution Image Uploads</label>
                        <input
                            type="file"
                            id="solutionImageUpload"
                            name="solutionImageUpload"
                            multiple
                            onChange={handleSolutionImageUpload}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                    </div>
                )}
            </div>

            <div className="mb-8">
                <label className="block text-lg font-medium mb-2">Difficulty Rating (1-5)</label>
                <div className="flex space-x-4">
                    {[1, 2, 3, 4, 5].map((value) => (
                        <label key={value} className="flex items-center">
                            <input
                                type="radio"
                                name="difficulty"
                                value={value}
                                checked={difficulty === value}
                                onChange={(e) => setDifficulty(parseInt(e.target.value))}
                                className="form-radio h-4 w-4 text-blue-600"
                            />
                            <span className="ml-2">{value}</span>
                        </label>
                    ))}
                </div>
            </div>


            <div className="flex justify-end space-x-4 mt-8">
                <button
                    onClick={() => navigate('/problems')}
                    className="bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Update Problem
                </button>
            </div>
        </div>
    );
};

export default EditProblemPage;
