import { Outlet, useNavigation } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import { MutatingDots } from "react-loader-spinner";

const LoadingIndicator = () =>
    <div className="container flex mx-auto h-full items-center justify-center">
        <div className="">
            <MutatingDots
                wrapperClass=""
            />
        </div>
    </div>;

const Root = () => {
    const navigation = useNavigation();

    return (
        <div className="h-screen flex flex-col">
            <Navbar />
            <div className="flex-grow">
                {navigation.state === "loading" ? <LoadingIndicator /> : <Outlet />}
            </div>
        </div>
    );
};

export default Root;
