import React, { useState } from 'react';

import testimonials from '../static/testimonials.json';

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    console.log(testimonials);

    return (
        <div className="mt-16">
            <h2 className="text-4xl font-bold mb-8 text-center">Testimonials</h2>
            <div className="flex justify-between items-center mb-4">
                <button onClick={handlePrev} className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg">
                    &larr;
                </button>
                <div className="mx-4 text-center flex-1 whitespace-pre-wrap">
                    <p className="mt-2 text-justify">"{testimonials[currentIndex].text}"</p>
                    <p className="text-sm text-gray-600 mt-1">- {testimonials[currentIndex].author}</p>
                </div>
                <button onClick={handleNext} className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg">
                    &rarr;
                </button>
            </div>
            <p className="text-center text-gray-500 mt-4">Taken from <a className="text-blue-500" href="https://nextdoor.com/pages/ap-guidance-llc-cupertino-ca/">Nextdoor page</a></p>
        </div>
    );
};

export default Testimonials;
