import { useState } from "react";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import { add_user, delete_user, get_users_permissions, update_user_permissions } from "../util/server_util";

const PermissionsTab = () => {
    const full_perms_list = ["student", "teacher", "problemers", "admin"];
    const users = useLoaderData();
    const navigate = useNavigate();
    const [expandedUserId, setExpandedUserId] = useState(null);
    const [updatedPermissions, setUpdatedPermissions] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(users);

    const toggleCard = (user) => {
        const userId = user.email;
        if (!Object.hasOwn(user, 'perms')) {
            return;
        }
        const perms = user.perms;
        if (expandedUserId === userId) {
            setExpandedUserId(null); // Collapse if already expanded
        } else {
            setExpandedUserId(userId);
            let x = {};
            for (const y of full_perms_list) {
                if (perms.includes(y)) {
                    x[y] = true;
                }
                else {
                    x[y] = false;
                }
            }
            console.log(x);
            setUpdatedPermissions(x); // Reset permissions when a new card is expanded
        }
    };

    const handlePermissionChange = (e) => {
        setUpdatedPermissions((prev) => ({
            ...prev,
            [e.target.id]: !prev[e.target.id],
        }))
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredUsers(
            users.filter((user) => user.email.toLowerCase().includes(query))
        );
    };

    const handleAddUser = (e) => {
        e.stopPropagation();
        
        if (newUserEmail) {
            // onAddUser(newUserEmail); // Pass the new user email to the parent component's handler
            console.log("adding user", newUserEmail);
            add_user(newUserEmail).then(() => {
                setNewUserEmail(''); // Clear the input field after adding
            })
            navigate("/admin/permissions");
        }
    };

    const handleDeleteUser = async (e, userId) => {
        e.stopPropagation();

        // onAddUser(newUserEmail); // Pass the new user email to the parent component's handler
        console.log("deleting user", userId);
        await delete_user(userId);
        navigate("/admin/permissions");
    };

    const handleUpdate = (e, userId) => {
        e.stopPropagation(); // Prevent the card from collapsing
        console.log(e);
        // Handle the update logic here (e.g., make an API call to update the user's permissions)
        console.log('Updating permissions for user:', userId, updatedPermissions);

        // Collapse the card after updating
        let perms_list = [];
        for (let x in updatedPermissions) {
            if (updatedPermissions[x]) {
                perms_list.push(x);
            }
        }
        setExpandedUserId(null);
        update_user_permissions(userId, perms_list).then(() => {
            navigate("/admin/permissions");
        }).catch(() => {});
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between mb-4">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search by name..."
                    className="p-2 border border-gray-300 rounded-lg w-1/2"
                />
                <div className="flex items-center">
                    <input
                        type="email"
                        value={newUserEmail}
                        onChange={(e) => setNewUserEmail(e.target.value)}
                        placeholder="Add user by email..."
                        className="p-2 border border-gray-300 rounded-lg mr-2"
                    />
                    <button
                        onClick={handleAddUser}
                        className="bg-green-500 text-white font-bold py-2 px-4 rounded-lg"
                    >
                        Add User
                    </button>
                </div>
            </div>

            <div className="space-y-4">
                {filteredUsers.map((user) => (
                    <div
                        key={user.email}
                        className={`border rounded-lg p-4 ${expandedUserId === user.email ? 'bg-gray-100' : 'bg-white'} shadow-md`}
                        onClick={() => toggleCard(user)}
                    >
                        <div className="flex justify-between items-center">
                            <div>
                                <h2 className="text-xl font-bold">{user.name}</h2>
                                <p className="text-gray-600">{user.email}</p>
                                <div className="mt-2">
                                    {Object.hasOwn(user, 'perms') && user.perms.map((perm) => (
                                        <span key={perm} className="inline-block bg-blue-200 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold mr-2">
                                            {perm}
                                        </span>
                                    ))}
                                </div>
                                {expandedUserId === user.email && (
                                    <button
                                        onClick={(e) => handleDeleteUser(e, user.email)}
                                        className="bg-red-500 text-white font-bold py-2 px-4 rounded-lg mr-2 mt-4"
                                    >
                                        Delete
                                    </button>
                                )}
                            </div>
                            {expandedUserId === user.email && (
                                <div className="flex flex-col">
                                    {
                                        full_perms_list.map(perm => (
                                            <label className="inline-flex items-center mt-2" onClick={e => e.stopPropagation()} key={perm}>
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={user.perms.includes(perm)}
                                                    className="form-checkbox h-4 w-4 text-blue-600"
                                                    id={perm}
                                                    onChange={handlePermissionChange}
                                                />
                                                <span className="ml-2">{perm}</span>
                                            </label>
                                        ))
                                    }
                                    <button
                                        onClick={(e) => handleUpdate(e, user.email)}
                                        className="bg-blue-500 text-white font-bold py-2 px-4 mt-2 rounded-lg"
                                    >
                                        Update
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PermissionsTab;

export const permissions_tab_loader = async () => {
    try {
        const perms = await get_users_permissions();
        return perms.data;
    }
    catch (e) {
        return redirect("/login");
    }
}
