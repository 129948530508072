/*
  a problemer can see:
  his own problems that he has written
  a button to create new problem

  each problem that he sees will have:
  Title
  link to problem page
*/

import React from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import ProblemCard from '../components/ProblemCard';
import { getProblems, logout } from '../util/server_util';

const ProbremerPortal = () => {
    const navigate = useNavigate();
    const data = useLoaderData();
    if (!data.loggedin) {
        return (
            <div className="h-screen flex flex-col justify-center items-center">
                <p className="text-xl font-bold mb-4">You do not have permissions to view this page</p>
                <button
                    onClick={() => navigate('/')}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
                >
                    Go Back to Home
                </button>
            </div>
        );
    }
    const problems = data.problems.data;

    const handleCreateProblem = async () => {
        navigate("/new-problem");
    };

    const handleLogout = async () => {
        await logout();
        navigate("/login");
    };

    return (
        <div className="container mx-auto p-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl font-bold">Your Problems</h1>
                <div className="flex space-x-4">
                    <button
                        onClick={handleCreateProblem}
                        className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
                    >
                        Create New Problem
                    </button>
                    <button
                        onClick={handleLogout}
                        className="bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
                    >
                        Logout
                    </button>
                </div>
            </div>
            <div>
                {problems.length === 0 ? (
                    <p>No problems found.</p>
                ) : (
                    problems.map((problem, i) => (
                        <ProblemCard key={i} problem={problem} />
                    ))
                )}
            </div>
        </div>
    );
};

export default ProbremerPortal;

export const problemer_loader = async () => {
    try {
        const problems = await getProblems();
        return {problems: problems, loggedin: true};
    }
    catch (e) {
        return {loggedin: false};
    }
}
